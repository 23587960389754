<template>
  <div>
    <p class="font-medium text-lg text-black mb-3">{{$t('Packages')}}</p>
    <shipblu-card class="md:p-6 p-5">
      <div class="sm:flex gap-4 items-start">
        <p class="font-medium text-black whitespace-no-wrap mt-2">{{$t('Number of Packages')}}</p>
        <div class="flex w-full sm:gap-4 gap-2 sm:mt-0 mt-2">
          <div class="w-full">
            <div class="package-input-number w-full h-10">
              <input class="w-full input-shadow h-full text-primary" name="number of packages" v-validate="'required|numeric|min_value:1|max_value:4'" :placeholder="$t('The number of flyers or boxes')" type="number" min="1" max="4" v-model.number="numberOfPackagesDisplay" />
            </div>
            <p class="text-danger text-sm" v-show="errors.has('number of packages')">{{ errors.first('number of packages') }}</p>
          </div>
          <label class="prompt cursor-pointer flex" v-if="type === 'Add Delivery Order'">
            <input v-model="shipment.is_customer_allowed_to_open_packages" :class="shipment.is_customer_allowed_to_open_packages ? 'checked' : ''" :value="shipment.is_customer_allowed_to_open_packages" type="checkbox" />
            <span class="h-10 sm:px-3 px-2 rounded flex sm:gap-2 gap-1 items-center">
              <p class="text-sm whitespace-no-wrap">{{$t('Allow open packages')}}</p>
              <img :src="shipment.is_customer_allowed_to_open_packages ? openPackage : dontOpen" class="w-8 h-8"/>
            </span>
          </label>
        </div>
      </div>
      <div class="mt-4">
        <div class="md:flex block gap-2 mt-3" v-for="(item, index) in packagesDetails" :key="index">
          <div class="flex gap-2 lg:mb-0 md:mb-1 sm:mb-0 mb-1 w-full items-start">
            <p class="input-shadow py-2 px-3 text-darkgray font-semibold">{{ index+1 }}</p>
            <textarea v-validate="type === 'Add Return' ? 'required' : ''" class="text-darkgray resize-none w-full input-order" :placeholder="$t('Add Package Description...')" :name="`package description-${index}`" v-model="item.description" rows="1"/>
            <span class="text-danger text-sm" v-show="errors.has(`package description-${index}`)">{{ errors.first(`package description-${index}`) ? errors.first(`package description-${index}`).split(`-${index}`)[0] + errors.first(`package description-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div class="flex gap-2 items-start md:mt-0 mt-1">
            <div class="md:w-40 w-full">
              <v-select v-validate="'required'" :name="`package size-${index}`" class="w-full select-border" v-model="item.package_size" :placeholder="$t('Package Size')" label="short_code" :options="packageSizes"/>
              <p class="text-danger text-sm" v-show="errors.has(`package size-${index}`)">{{ errors.first(`package size-${index}`) ? errors.first(`package size-${index}`).split(`-${index}`)[0] + errors.first(`package size-${index}`).split(`-${index}`)[1]  : '' }}</p>
            </div>
            <label class="prompt cursor-pointer">
              <input v-model="item.fragile" :class="item.fragile ? 'checked' : ''" :value="item.fragile" type="checkbox" />
              <span class="px-3 py-2 gap-1 rounded flex items-center">
                <p class="text-sm">{{$t('Fragile')}}</p>
                <img :src="item.fragile ? fragileActive : fragileInactive" class="w-5 h-5"/>
              </span>
            </label>
            <div @click="packagesDetails.length > 1 ? removePackage(index) : ''" :class="packagesDetails.length > 1 ? 'cursor-pointer' : ''" class="remove-package p-1 flex mt-1">
              <feather-icon icon="Trash2Icon" svgClasses="w-5 h-5 text-light-red"/>
            </div>
          </div>
        </div>
      </div>
      <div :class="type === 'Add Delivery Order' ? 'grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-2 grid-cols-1 gap-x-5 gap-y-3' : ''" class="mt-8">
        <div>
          <p class="mb-2 font-medium text-black">{{ type === 'Add Delivery Order' ? $t('Cash On Delivery (COD) ') + $t('EGP'): $t('Cash Refund')}}</p>
          <input :class="type === 'Add Delivery Order' ? ' w-full' : 'sm:w-1/2 w-full'" class="input-order" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="codDisplay">
          <p v-if="type === 'Add Delivery Order'" class="mt-2 text-icon text-sm leading-tight">
            {{ $t('This is the amount we will collect from your customer') }}
          </p>
          <div v-else class="mt-2 text-icon text-sm leading-tight">
            <p>{{ $t('This amount will be paid to your customer.')}}</p>
            <p>{{ $t('Refund orders will only be attempted if there are sufficient funds in your account.') }}</p>
          </div>
        </div>
        <div v-if="type === 'Add Delivery Order' && !parcels[1].checked && financialsInsurance.insurance_package.id && financialsInsurance.insurance_package.id !== 1">
          <span class="relative font-medium text-black pr-3">{{ $t('Products declared value') }}
            <vx-tooltip class="tooltip-icon" position="top" color="dark" :text="`${$t('ShipBlu Shield fees will be applied on this amount if it is provided.\n This amount will not be shown to your customer.')}`">
              <span class="material-icons-outlined text-sm text-black">info</span>
            </vx-tooltip>
          </span>
          <input class="input-order w-full mt-2" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="declaredValue">
        </div>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'
import fragileActive from '../../../assets/images/pages/fragile-active.svg'
import openPackage from '../../../assets/images/pages/open-packages.svg'
import dontOpen from '../../../assets/images/pages/Don\'t Open.svg'
import fragileInactive from '../../../assets/images/pages/fragile-inactive.svg'
import vSelect from 'vue-select'

export default {
  props: ['shipment', 'financialsInsurance', 'packages', 'checkValidation', 'packageSizes', 'type', 'resetData'],
  data () {
    return {
      choosenPackages: [],
      packagesDetails: [],
      fragileActive,
      fragileInactive,
      openPackage,
      dontOpen,
      parcels: [
        {
          name: 'Can\'t Open',
          checked: true
        },
        {
          name: 'Can Open',
          checked: false
        }
      ],
      removePackagesFlag: false
    }
  },
  watch: {
    'packages' () {
      this.$emit('packages', this.packagesDetails)
    },
    'checkValidation' () {
      this.$validator.validateAll().then(result => {
        this.shipment.number_of_packages = this.shipment.number_of_packages === '' ? 1 : this.shipment.number_of_packages
        if (result && this.shipment.number_of_packages > 0 && this.packagesDetails.every(packageItem => packageItem.package_size)) {
          this.$emit('allowToCreatePackage', true)
        }
      })
    },
    'shipment.number_of_packages' (val) {
      this.shipment.number_of_packages = val > 4 ? 4 : val < 1 ? 1 : val
      const insertedPackages = this.packagesDetails
      this.packagesDetails = []
      for (let index = insertedPackages.length; index < val; index++) {
        this.packagesDetails.push({
          package_size: '',
          description: '',
          fragile: false
        })
      }
      if (this.packagesDetails.length < 4) {
        insertedPackages.forEach(element => {
          this.packagesDetails.push(element)
        })
      }
      if (insertedPackages.length > val) {
        for (let index = 0; index < (insertedPackages.length - val); index++) {
          this.packagesDetails.pop()
        }
      }
      this.$emit('packages', this.packagesDetails)
    }
  },
  methods: {
    removePackage (index) {
      this.packagesDetails.splice(index, 1)
      this.removePackagesFlag = true
      this.packagesDetails = [...this.packagesDetails]
      this.$emit('packages', this.packagesDetails)
    },
    changeSize (shortCode) {
      switch (shortCode) {
      case 'S':
        return 'width: 20px;height: 25px'
      case 'M':
        return 'width: 25px; height: 30px;'
      case 'L':
        return 'width: 30px; height: 35px;'
      case 'XL':
        return 'width: 35px; height: 40px;'
      }
    }
  },
  computed: {
    numberOfPackagesDisplay: {
      get () {
        return this.packagesDetails.length === this.shipment.number_of_packages ? this.shipment.number_of_packages : this.packagesDetails.length
      },
      set (newValue) {
        this.shipment.number_of_packages = Number(newValue)
      }
    },
    codDisplay: {
      get () {
        return this.shipment.cash_amount ? this.shipment.cash_amount.toFixed(2) : '0.00'
      },
      set (newValue) {
        this.shipment.cash_amount = Number(newValue)
      }
    },
    declaredValue: {
      get () {
        return this.shipment.declared_value ? this.shipment.declared_value.toFixed(2) : '0.00'
      },
      set (newDeclaredValue) {
        this.shipment.declared_value = Number(newDeclaredValue)
      }
    }
  },
  components: {
    ShipbluCard,
    vSelect
  },
  created () {
    this.packagesDetails = this.packages
  }
}
</script>

<style lang="scss">
.input-shadow {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: none;
}
.remove-package {
  background: #fff;
  border: 1px solid #FE8383;
  box-shadow: 0px 2px 4px rgba(110, 109, 110, 0.25);
  border-radius: 6px;
}
.package-input-number {
  input {
    padding: 0px 16px;
    font-weight: 600;
    font-size: 14px;
    &::placeholder {
      font-weight: 400;
      color: #9DADC2;
    }
  }
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 9px;
    right: 16px;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top: 7.5px solid #E5E5E5;
    pointer-events: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: 9px;
    right: 16px;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-bottom: 7.5px solid #E5E5E5;
    pointer-events: none;
  }
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    height: 30px;
    width: 15px;
    cursor: pointer;
  }
}
</style>