<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="cashCollectionModal"
    :title="$t(type)"
    :buttons-hidden="true" autocomplete="nofill">
      <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2 mt-2">
        <div class="col-span-1">
          <vs-input autocomplete="nofill" v-validate="'required'" :label-placeholder="$t('Full Name') + '*'" name="full name" v-model="shipment.full_name" :class="shipment.full_name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
          <span class="text-danger text-sm" v-show="errors.has('full name')">{{ errors.first('full name') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input autocomplete="nofill" v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" :label-placeholder="$t('Phone Number') + '*'" name="phone number" v-model="shipment.phone" :class="shipment.phone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
          <span class="text-danger text-sm" v-show="errors.has('phone number')">{{ errors.first('phone number') }}</span>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
        <div v-if="!showAdditional" class="relative flex items-center justify-start mt-6">
          <div @click="showAdditional = true" class="p-1 flex self-center bg-primary rounded cursor-pointer">
            <feather-icon icon="PlusIcon" svgClasses="w-4 h-4 text-white" />
          </div>
          <div class="add-products">
            <span class="text-sm font-medium py-1 px-2 rounded text-lightgray">{{$t('Additional Phone Number')}}</span>
          </div>
        </div>
        <div class="col-span-2 mt-2" v-if="showAdditional">
          <vs-input autocomplete="nofill" v-validate="{ regex: /(^0[0-9]\d{8,15}$|^\u0660\u0661[\u0660-\u0669][\u0660-\u0669]{8,15}$)/ }" name="additional phone number" :label-placeholder="$t('Additional Phone Number')" v-model="shipment.secondary_phone" :class="shipment.secondary_phone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
          <span class="text-danger text-sm" v-show="errors.has('additional phone number')">{{ errors.first('additional phone number') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4 mt-4">
        <div class="col-span-1">
          <v-select v-on:input="shipment.customer_governorate ? loadCities(`${shipment.customer_governorate.id}`) : ''" :class="shipment.customer_governorate ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" name="governorate" v-model="shipment.customer_governorate" v-validate="'required'" :placeholder="$t('Governorate') + '*'" label="name" :options="governorates"/>
          <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
        </div>
        <div class="col-span-1">
          <v-select v-on:input="shipment.customer_city ? loadZones(`${shipment.customer_city.id}`) : ''" :class="shipment.customer_city ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" name="city" v-model="shipment.customer_city"
            v-validate="'required'" :placeholder="$t('City') + '*'" label="name" :options="cities"/>
          <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
        </div>
        <div class="col-span-1">
          <v-select :class="shipment.address.customerZone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" v-model="shipment.address.customerZone" v-validate="'required'"
            name="zone" :placeholder="$t('Zone') + '*'" label="name" :options="zones"/>
          <span class="text-danger text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4 mt-4">
        <div class="vx-col w-full">
          <vs-input autocomplete="nofill" v-validate="'required'" name="address 1" :label-placeholder="$t('Customer Address 1') + '*'" v-model="shipment.address.line_1" class="w-full input-customer-info"/>
          <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
        </div>
        <div class="vx-col w-full">
          <vs-input autocomplete="nofill" v-validate="'required'" name="address 2" :label-placeholder="$t('Customer Address 2') + '*'" v-model="shipment.address.line_2" class="w-full input-customer-info"/>
          <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
        </div>
        <div class="sm:flex items-center justify-between mt-6">
          <div class="flex items-center sm:justify-start justify-center">
            <img src="@/assets/images/logo/w3w.svg" alt="">
            <p class="leading-tight text-darkgray font-medium mx-1 text-xs text-lightgray">{{ $t('(Optional)') }}</p>
            <a target="blank" href="https://what3words.com/tricycle.hotel.jeering" class="material-icons-outlined text-primary text-2xl cursor-pointer">help_center</a>
          </div>
          <div class="sm:m-0 m-auto sm:mt-0 mt-3">
            <what3words-autosuggest clip_to_country="EG" :api_key="W3W_API_KEY">
              <input class="w-68 input-on" v-model="shipment.address.what3words" name="w3w" id="what3words"/>
            </what3words-autosuggest>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <input class="w-full" :class="shipment.cash_amount || shipment.cash_amount === 0 ? 'input-on' : 'input-off'" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="codDisplay">
      </div>
      <div v-if="orderPrice.subtotal" class="estimated-price mt-8">
        <p class="text-blue-900 font-medium">{{ $t('Estimated Price')}}</p>
        <div class="mt-3 flex justify-between text-sm text-lightgray">
          <p>{{ $t('Package base price')}}</p>
          <p class="font-medium">{{ Number(orderPrice.subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
        <div class="mt-2 flex justify-between text-sm text-lightgray">
          <p>{{ $t('Vat (14%)')}}</p>
          <p class="font-medium">{{ Number(orderPrice.vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
        <div class="mt-2 flex justify-between text-sm text-lightgray">
          <p>{{ $t('Postal taxes (10%)')}}</p>
          <p class="font-medium">{{ Number(orderPrice.postal_tax).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
        <div class="mt-2 flex justify-between text-sm">
          <p class="text-blue-900 font-medium">{{ $t('Total')}}</p>
          <p class="font-semibold text-primary">{{ Number(orderPrice.total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="addCashCollection" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
  </shipblu-prompt>
</template>
<script>
import vSelect from 'vue-select'
import common from '../../../assets/utils/common'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: [
    'cashCollectionModal', 'type', 'shipment', 'governorates', 
    'orderPrice'
  ],
  data () {
    return {
      W3W_API_KEY: process.env.VUE_APP_WHAT3WORDS_API_KEY,
      location: {},
      cities: [],
      zones: [],
      showAdditional: false
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  computed: {
    codDisplay: {
      get () {
        return this.shipment.cash_amount ? this.shipment.cash_amount.toFixed(2) : '0.00'
      },
      set (newValue) {
        this.shipment.cash_amount = Number(newValue)
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('cashCollectionModal', false)
      this.showAdditional = false
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    },
    addCashCollection () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('addCashCollection')
        }
      })
    }
  }
}
</script>

<style scoped>
.logo {
  background: white;
  padding: 1px 3px;
  font-size: 8px;
  color: #e11f26;
  border: 2px solid #e11f26;
  margin: 0px 5px 5px 0px;
}
</style>
<style>
.what3words-autosuggest>.what3words-autosuggest>.what3words-autosuggest-error-wrapper {
  display: none !important;
}
</style>
