<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="shipmentModal"
    :title="$t(type)"
    :buttons-hidden="true">
    <form :v-model="shipment" autocomplete="nofill" class="mt-6">
      <div class="md:flex justify-between" v-if="type === 'Add Delivery Order'">
        <div class="flex items-center justify-center">
          <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
          <p class="leading-tight text-darkgray font-medium">{{ $t('How will we get your package?') }}</p>
        </div>
        <div class="flex flex-wrap items-center justify-center md:mt-0 mt-2 gap-2">
          <div v-for="(dropOff, index) in dropOffChoices" :key="index">
            <label @click="checkedItem(dropOff, dropOffChoices)" class="prompt cursor-pointer">
              <input v-model="shipment.is_counter_dropoff" :class="dropOff.checked ? 'checked' : ''" :value="dropOff.id" type="radio" />
              <span class="py-2 px-4 rounded text-sm">{{ $t(dropOff.name) }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="md:flex block m-auto gap-4 mt-6" v-if="orderType === 'Regular' && shipment.is_counter_dropoff && (shipment.is_counter_dropoff === 2 || shipment.is_counter_dropoff.id === 2)">
        <div class="items-center sm:justify-start justify-center flex">
          <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
          <p class="leading-tight text-darkgray font-medium whitespace-nowrap">{{type === 'Add Delivery Order' ? $t('Select your pickup point:') : $t('Select your dropoff point:')}}</p>
        </div>
        <div class="md:mt-0 mt-2 w-full">
          <v-select :class="shipment.pickupPoint ? 'pickup-point-filled' : ''" class="pickup-point w-full" v-model="shipment.pickupPoint" v-validate="'required'"
            :placeholder="type === 'Add Delivery Order' ? $t('Pickup Point') : $t('DropOff Point') + '*'" name="pickup point" label="fullAddress" :options="pickupPoints"/>
          <span class="text-danger text-sm" v-show="errors.has('pickup point')">{{ errors.first('pickup point') }}</span>
        </div>
      </div>
      <div class="flex mt-6 items-center sm:justify-start justify-center">
        <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
        <p class="text-lg text-darkgray font-medium">{{$t('Customer Information & Address')}}</p>
      </div>
      <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-4 mt-2">
        <div class="col-span-1" :class="!selectionInProgress ? 'flex items-end' : ''">
          <div v-if="selectionInProgress" class="relative">
            <vs-input autocomplete="nofill" :class="searchPhoneVal ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" :label-placeholder="$t('Phone Number') + '*'" name="phone number"  v-model="searchPhoneVal"
              v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }"/>
            <span class="absolute text-primary" style="top: 5px; right: 7px;">
              <feather-icon class="w-4 cursor-pointer" icon="XIcon" @click="convertTextToSelect()"></feather-icon>
            </span>
          </div>
          <v-select v-else :class="shipment.phone ? 'input-customer-info-filled' : '' " class="w-full input-customer-info" id="phone-number" v-model="shipment.phone"
          :placeholder="$t('Phone Number') + '*'" autocomplete="nofill" name="phone number" label="label"  :options="phones" search @search="handlePhoneNumberSearch" @keyup.enter.native="handlePhoneNumberSearch"/>
          <span class="text-danger text-sm" v-show="errors.has('phone number')">{{ errors.first('phone number') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input :class="shipment.full_name ? 'input-customer-info-filled' : ''" autocomplete="nofill" class="w-full input-customer-info" v-validate="'required'" :label-placeholder="$t('Full Name') + '*'" name="full name" v-model="shipment.full_name"/>
          <span class="text-danger text-sm" v-show="errors.has('full name')">{{ errors.first('full name') }}</span>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
        <div v-if="!showAdditional" class="relative flex items-center justify-start mt-6">
          <div @click="showAdditional = true" class="p-1 flex self-center bg-primary rounded cursor-pointer">
            <feather-icon icon="PlusIcon" svgClasses="w-4 h-4 text-white" />
          </div>
          <div class="add-products">
            <span class="text-sm font-medium py-1 px-2 rounded text-lightgray">{{$t('Additional Phone Number')}}</span>
          </div>
        </div>
        <div class="col-span-2 mt-2" v-if="showAdditional">
          <vs-input autocomplete="nofill" name="additional phone number" v-validate="{ regex: /(^0[0-9]\d{8,15}$|^\u0660\u0661[\u0660-\u0669][\u0660-\u0669]{8,15}$)/ }" :class="shipment.secondary_phone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" :label-placeholder="$t('Additional Phone Number')" v-model="shipment.secondary_phone"/>
          <span class="text-danger text-sm" v-show="errors.has('additional phone number')">{{ errors.first('additional phone number') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4 mt-4">
        <div class="col-span-1">
          <v-select v-on:input="shipment.customer_governorate ? loadCities(`${shipment.customer_governorate.id}`) : ''" :class="shipment.customer_governorate ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" name="governorate" v-model="shipment.customer_governorate" v-validate="'required'" :placeholder="$t('Governorate') + '*'" label="name" :options="governorates"/>
          <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
        </div>
        <div class="col-span-1">
          <v-select v-on:input="shipment.customer_city ? loadZones(`${shipment.customer_city.id}`) : ''" :class="shipment.customer_city ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" name="city" v-model="shipment.customer_city"
            v-validate="'required'" :placeholder="$t('City') + '*'" label="name" :options="cities"/>
          <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
        </div>
        <div class="col-span-1">
          <v-select :class="shipment.address.customerZone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" v-model="shipment.address.customerZone" v-validate="'required'"
            name="zone" :placeholder="$t('Zone') + '*'" label="name" :options="zones"/>
          <span class="text-danger text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-1" v-if="shipment.handShakeType === 1">
        <div class="col-span-1 mt-2">
          <vs-input autocomplete="nofill" :class="shipment.address.line_1 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" name="address 1" :label-placeholder="$t('Customer Address 1') + '*'" v-model="shipment.address.line_1"/>
          <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
        </div>
        <div class="col-span-1 mt-2">
          <vs-input autocomplete="nofill" :class="shipment.address.line_2 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" name="address 2" :label-placeholder="$t('Customer Address 2') + '*'" v-model="shipment.address.line_2"/>
          <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
        </div>
        <div class="sm:flex items-center justify-between mt-6">
          <div class="flex items-center sm:justify-start justify-center">
            <img src="@/assets/images/logo/w3w.svg" alt="">
            <p class="leading-tight text-darkgray font-medium mx-1 text-xs text-lightgray">{{ $t('(Optional)') }}</p>
            <a target="blank" href="https://what3words.com/tricycle.hotel.jeering" class="material-icons-outlined text-primary text-2xl cursor-pointer">help_center</a>
          </div>
          <div class="sm:m-0 m-auto sm:mt-0 mt-3">
            <what3words-autosuggest clip_to_country="EG" :api_key="W3W_API_KEY">
                <input class="w-68 input-on" v-model="shipment.address.what3words" name="w3w" id="what3words"/>
            </what3words-autosuggest>
          </div>
        </div>
      </div>
      <div>
        <div class="vx-row" v-if="shipment.handShakeType === 2">
          <div class="vx-col w-full">
            <v-select autocomplete="nofill" class="select-large mt-8 w-full" name="pudo" v-model="shipment.pudo"
              v-validate="'required'" :placeholder="$t('PUDO') + '*'" label="display_name" :options="pudos"/>
            <span class="text-danger text-sm" v-show="errors.has('pudo')">{{ errors.first('pudo') }}</span>
          </div>
        </div>
        <div class="vx-row hidden">
          <div class="vx-col w-1/4" v-for="handShake in handShakes" :key="handShake.index">
            <vs-radio class="mt-8 w-full" v-model="shipment.handShakeType" :vs-value="handShake.id">{{handShake.short_code}}</vs-radio>
          </div>
        </div>
        <div class="vx-row flex justify-between mt-6">
          <div class="vx-col flex items-center sm:m-0 m-auto">
            <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
            <p class="leading-tight text-darkgray font-medium">{{ $t('Packages') }}</p>
          </div>
        </div>
        <div>
          <div v-if="packageSizes">
            <div class="grid md:grid-cols-4 grid-cols-2 gap-4 items-end mt-3">
              <div v-for="(item, index) in packageSizes" :key="index">
                <div class="supply-table-cell-content flex items-center justify-between col-span-1" v-if="item.short_code != 'OSZ'">
                  <div class="flex items-center">
                    <img src="../../../assets/images/pages/Mask-group.svg" :style="changeSize(item.short_code.split('-FLY')[0])"/>
                    <span class="leading-tight text-lightgray whitespace-nowrap ml-1">{{ item.short_code.split('-FLY')[0] + '-Flyer' }}</span>
                  </div>
                  <div v-if="packages.length < 4" class="p-1 flex self-center bg-primary rounded">
                    <feather-icon @click="addPackage(item)" class="cursor-pointer" icon="PlusIcon" svgClasses="w-4 h-4 text-white" />
                  </div>
                </div>
              </div>
            </div>
            <p class="text-danger sm:text-left text-center text-sm mt-2" v-if="packageSizes.length > 0 && packages.length === 0 && clicks > 0"> {{ $t('This field is required') }}</p>
          </div>
          <div v-if="packages" class="w-full mt-4">
            <div v-for="(item, index) in packages" :key="index">
              <div class="flex items-center mt-4">
                <div class="small-card py-2 px-3 flex items-center">
                  <img src="../../../assets/images/pages/Mask-group.svg" :style="changeSize(item.package_size.short_code? item.package_size.short_code.split('-FLY')[0] : '')"/>
                  <span class="leading-tight whitespace-nowrap font-medium text-primary ml-1">{{ item.package_size.short_code ? item.package_size.short_code.split('-FLY')[0] + '-Flyer' : '' }}</span>
                </div>
                <div v-if="type === 'Add Return'" class="w-full mx-3">
                  <textarea v-validate="'required'" name="description" class="flex resize-none w-full description sm:visible invisible" :placeholder="$t('Add Description')" v-model="item.description" rows="2"/>
                  <span class="sm:visible invisible text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>
                </div>
                <textarea v-else class="resize-none w-full mx-3 description sm:visible invisible" :placeholder="$t('Add Description')" v-model="item.description" rows="2"/>
                <label class="prompt cursor-pointer">
                  <input v-model="item.fragile" :class="item.fragile ? 'checked' : ''" :value="item.fragile" type="checkbox" />
                  <span class="py-2 px-3 rounded flex items-center">
                    <p class="text-sm mr-1">{{$t('Fragile')}}</p>
                    <img src="../../../assets/images/pages/fragile.png" class="w-4 h-5"/>
                  </span>
                </label>
                <div class="remove-package-icon ml-3 cursor-pointer">
                  <feather-icon @click="removePackage(index)" icon="XIcon" svgClasses="w-3 h-3"/>
                </div>
              </div>
              <template v-if="type === 'Add Return'">
                <textarea v-validate="'required'" name="description" class="resize-none w-full description sm:hidden" :placeholder="$t('Add Description')" v-model="item.description" rows="2"/>
                <span class="sm:hidden text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>
              </template>
              <textarea v-else name="description" class="resize-none mt-3 w-full description sm:hidden" :placeholder="$t('Add Description')" v-model="item.description" rows="2"/>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:flex items-center justify-between mt-6" v-if="type === 'Add Delivery Order'">
        <div>
          <div class="flex items-center sm:justify-start justify-center">
            <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
            <p class="leading-tight text-darkgray font-medium">{{ $t('The parcel') }}</p>
          </div>
        </div>
        <div class="flex items-center justify-center sm:mt-0 mt-2">
          <div v-for="(parcel, index) in parcels" :key="index">
            <label @click="checkedItem(parcel, parcels)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
              <input v-model="shipment.is_customer_allowed_to_open_packages" :class="parcel.checked ? 'checked' : ''" :value="parcel.name" type="radio" />
              <span class="py-2 px-4 rounded text-sm">{{ $t(parcel.name) }}</span>
            </label>
          </div>
        </div>
      </div>
      <div v-if="parcels[1].checked && financialsInsurance.insurance_package.id && financialsInsurance.insurance_package.id !== 1" class="flex items-center sm:text-left text-center sm:w-3/5 sm:px-2">
        <li class="w-2 mr-2" style="color:#FF7556;"></li>
        <img width="18px" height="18px" src="@/assets/images/pages/insurance-parcel.png" alt="">
        <span class="text-sm text-icon ml-2">{{$t('ShipBlu Shield will not be applicable on parcels allowed to be open')}}</span>
      </div>
      <div class="sm:flex justify-between mt-6">
        <div class="flex items-center justify-center">
          <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
          <p class="leading-tight text-darkgray font-medium">{{ $t('Service Level') }}</p>
        </div>
        <div class="flex items-center justify-center sm:mt-0 mt-2">
          <div v-for="(serviceLevel, index) in serviceLevels" :key="index">
            <label @click="checkedItem(serviceLevel, serviceLevels)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
              <input v-model="shipment.serviceLevel" :class="shipment.serviceLevel === serviceLevel ? 'checked' : ''" :value="serviceLevel" type="radio" />
              <span class="py-2 px-4 rounded text-sm">{{ serviceLevel.name }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="sm:flex items-start justify-between mt-6">
        <div>
          <div class="flex items-center sm:justify-start justify-center">
            <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
            <p class="leading-tight text-darkgray font-medium">{{ type === 'Add Delivery Order' ? $t('Cash On Delivery (COD)') : $t('Cash Refund') }}</p>
          </div>
          <div class="flex sm:items-center sm:text-left text-center m-auto sm:w-76 sm:px-2">
            <li class="w-2" style="color:#FF7556;"></li>
            <span class="text-sm text-icon ml-2">{{$t('Please, do not forget to add any applicable fees')}} {{type === 'Add Delivery Order' ? $t('(delivery, CoD) to CoD Amount!') : $t('(return, refund) to return Amount!')}}</span>
          </div>
        </div>
        <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
          <input class="w-68 text-right" :class="shipment.cash_amount || shipment.cash_amount === 0 ? 'input-on' : 'input-off'" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="codDisplay">
        </div>
      </div>
      <div class="sm:flex items-start justify-between mt-6" v-if="type === 'Add Delivery Order' && !parcels[1].checked && financialsInsurance.insurance_package.id && financialsInsurance.insurance_package.id !== 1">
        <div>
          <div class="flex items-center sm:justify-start justify-center">
            <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
            <p class="leading-tight text-darkgray font-medium">{{ $t('Products declared value') }}</p>
          </div>
          <div class="flex sm:items-center sm:text-left text-center m-auto sm:w-76 sm:px-2">
            <li class="w-2" style="color:#FF7556;"></li>
            <span class="text-sm text-icon ml-2">{{$t('Insurance fees will be applied on this amount in case the COD amount = 0')}}</span><br>
          </div>
          <div class="flex sm:items-center sm:text-left text-center m-auto sm:px-2">
            <span class="text-sm text-icon ml-2">({{$t('This amount will not be shown to your customer')}})</span>
          </div>
        </div>
        <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
          <input class="w-68 text-right input-on" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="declaredValue" style="background: #F1F3F6 !important;">
        </div>
      </div>
      <div class="sm:flex items-center justify-between mt-6">
        <div class="flex items-center sm:justify-start justify-center">
          <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
          <p class="leading-tight text-darkgray font-medium">{{ $t('Reference Number') }}</p>
        </div>
        <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
          <input autocomplete="nofill" class="w-68" :class="!shipment.merchant_order_reference ? 'input-off' : 'input-on'" type="text" v-validate="'max:30'" :placeholder="$t('Merchant Order Reference No.')" name="reference" v-model="shipment.merchant_order_reference">
          <p class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first('reference') }}</p>
        </div>
      </div>
      <div class="mt-6">
        <div class="flex items-center sm:justify-start justify-center">
          <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
          <p class="leading-tight text-darkgray font-medium">{{ $t('Do you want to leave notes?') }}</p>
        </div>
        <textarea class="resize-none w-full mt-3 notes" :placeholder="$t('your notes..')" rows="5" v-model="shipment.order_notes"/>
        <div class="flex justify-end">
          <li class="w-2" style="color:#FF7556;"></li>
          <span class="text-sm text-icon ml-2">{{ $t('"Notes" will be printed on the order\'s label.') }}</span>
        </div>
      </div>
      <div v-if="orderType === 'Regular'" class="sm:flex items-center justify-between mt-4">
        <div class="flex items-center sm:justify-start justify-center">
          <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
          <p class="leading-tight text-darkgray font-medium">{{ $t('Promo Codes') }}</p>
        </div>
        <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
          <input class="w-68" :class="!shipment.promoCode ? 'input-off' : 'input-on'" type="text" v-validate="'max:30'" name="promo code" :placeholder="$t('Promo Code')"  v-model="shipment.promoCode">
          <p class="text-danger text-sm" v-show="errors.has('promo code')">{{ errors.first('promo code') }}</p>
        </div>
      </div>
      <div v-if="orderPrice.subtotal" class="estimated-price mt-8">
        <p class="text-blue-900 font-medium">{{ $t('Estimated Price')}}</p>
        <div class="mt-3 flex justify-between text-sm text-lightgray">
          <p>{{ $t('Package base price')}}</p>
          <p class="font-medium">{{ Number(orderPrice.subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
        <div class="mt-2 flex justify-between text-sm text-lightgray">
          <p>{{ $t('Vat (14%)')}}</p>
          <p class="font-medium">{{ Number(orderPrice.vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
        <div class="mt-2 flex justify-between text-sm text-lightgray">
          <p>{{ $t('Postal taxes (10%)')}}</p>
          <p class="font-medium">{{ Number(orderPrice.postal_tax).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
        <div v-if="financialsInsurance.insurance_package.id && financialsInsurance.insurance_package.id !== 1" class="mt-3 flex justify-between text-sm text-lightgray">
          <p>{{ $t('ShipBlu Shield Fees')}}</p>
          <p class="font-medium">{{ Number(orderPrice.insurance_fees).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
        <div class="mt-2 flex justify-between text-sm">
          <p class="text-blue-900 font-medium">{{ $t('Total')}}</p>
          <p class="font-semibold text-primary">{{ Number(orderPrice.total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
      </div>
    </form>
    <div class="flex justify-center mt-8">
      <vs-button class="font-medium p-2 w-1/2" type="filled" @click="addShipment()">{{type === 'Add Delivery Order' ? $t('Create Order') : $t('Create Return')}}</vs-button>
    </div>
  </shipblu-prompt>
</template>
<script>
import ShipbluVSelect from 'shipblu-vue-select'
import vSelect from 'vue-select'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import common from '../../../assets/utils/common'
import { sendRequest }  from '../../../http/axios/requestHelper'

export default {
  props: [
    'shipmentModal', 'estimatePrice', 'type', 'pudos', 'shipment', 'governorates', 'orderPrice',
    'packageSizes', 'handShakes', 'packages', 'serviceLevels', 'pickupPoints'
  ],
  data () {
    return {
      financialsInsurance:{
        insurance_package: {}
      },
      W3W_API_KEY: process.env.VUE_APP_WHAT3WORDS_API_KEY,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      cities: [],
      zones: [],
      dropOffChoices: [
        {
          id: 2,
          name: 'Pickup',
          checked: true
        },
        {
          id: 1,
          name: 'Counter DropOff',
          checked: false
        }
      ],
      parcels: [
        {
          name: 'Can\'t Open',
          checked: true
        },
        {
          name: 'Can Open',
          checked: false
        }
      ],
      orderTypes: [
        {
          name: 'Regular',
          checked: true
        }
      ],
      phones:[],
      orderType: 'Regular',
      merchant: {},
      searchVal: '',
      searchPhoneVal: '',
      searchInProgress: false,
      searchedPhoneValue: ' ',
      clicks: 0,
      selectionInProgress: false,
      showAdditional: false
    }
  },
  watch: {
    'shipment.phone' (selected) {
      if (typeof (selected) === 'object') {
        this.shipment.full_name = selected ? selected.customer.full_name : ''
        this.shipment.phone = selected ? selected.customer.phone : ''
        this.shipment.email = selected && selected.customer.email !== null ? selected.customer.email : ''
        this.shipment.customer_governorate = selected ? selected.customer.address.zone.city.governorate : ''
        this.shipment.customer_city = selected ? selected.customer.address.zone.city : ''
        this.shipment.address.customerZone = selected ? selected.customer.address.zone : ''
        this.shipment.handShakeType = 1
        this.shipment.secondary_phone = selected && selected.customer.secondary_phone !== null ? selected.customer.secondary_phone : null
        this.shipment.address.line_1 = selected && selected.customer.address.line_1 !== null ? selected.customer.address.line_1 : ''
        this.shipment.address.line_2 = selected && selected.customer.address.line_2 !== null ? selected.customer.address.line_2 : ''
      }
      if (!this.selectionInProgress && this.shipment.customer_governorate) {
        this.$emit('loadServicesAvailability', this.shipment.customer_governorate.id)
        this.loadCities(this.shipment.customer_governorate.id)
      }
    },
    'shipment.customer_governorate.id' (val) {
      if (this.shipment.customer_governorate && this.type !== 'Add Cash Collection') {
        this.$emit('loadServicesAvailability', val)
        this.loadCities(val)
      }
    },
    'serviceLevels' () {
      this.shipment.serviceLevel = this.serviceLevels[0]
    },
    'shipment.serviceLevel' (val) {
      if (this.serviceLevels) {
        this.shipment.serviceLevel = val
      }
    },
    'shipmentModal' (val) {
      if (val === true) {
        this.parcels = [
          {
            name: 'Can\'t Open',
            checked: true
          },
          {
            name: 'Can Open',
            checked: false
          }
        ]
        this.selectionInProgress = false
        this.merchant = JSON.parse(localStorage.getItem('merchant'))
        this.loadFinancialsInsurance()
        this.orderType = 'Regular'
        this.orderTypes[0].checked = true
        this.dropOffChoices.forEach(item => {
          item.id === 2 ? item.checked = true : item.checked = false
        })
        this.searchVal = ''
        this.searchPhoneVal = ''
        this.orderProduct = {}
      }
    },
    'searchPhoneVal' (val) {
      if (typeof (val) === 'object') {
        this.shipment.full_name = val.customer.full_name
        this.shipment.phone = val.customer.phone
        this.shipment.email = val.customer.email === null ? '' : val.customer.email
        this.shipment.customer_governorate = val.customer.address.zone.city.governorate
        this.shipment.customer_governorate.id = val.customer.address.zone.city.governorate.id
        this.shipment.customer_city = val.customer.address.zone.city
        this.shipment.customer_city.id = val.customer.address.zone.city.id
        this.shipment.address.customerZone = val.customer.address.zone
        this.shipment.handShakeType = 1
        this.shipment.secondary_phone = 
        this.shipment.secondary_phone === null ? '' : val.customer.secondary_phone
        this.shipment.address.line_1 = 
        this.shipment.address.line_1 === null ? '' : val.customer.address.line_1
        this.shipment.address.line_2 = 
        this.shipment.address.line_2 === null ? '' : val.customer.address.line_2
      }
    },
    'shipment.is_counter_dropoff' (val) {
      if (this.type === 'Add Delivery Order' && (val === 3 || val.id === 3)) {
        this.orderType = 'Fulfillment'
      } else if (this.type === 'Add Delivery Order') {
        this.orderType = 'Regular'
      }
    },
    'shipment.customer_city.id' (val) {
      if (this.shipment.customer_city && val && this.type !== 'Add Cash Collection') {
        this.loadZones(val)
      }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    codDisplay: {
      get () {
        return this.shipment.cash_amount ? this.shipment.cash_amount.toFixed(2) : '0.00'
      },
      set (newValue) {
        this.shipment.cash_amount = Number(newValue)
      }
    },
    declaredValue: {
      get () {
        return this.shipment.declared_value ? this.shipment.declared_value.toFixed(2) : '0.00'
      },
      set (newDeclaredValue) {
        this.shipment.declared_value = Number(newDeclaredValue)
      }
    }
  },
  components: {
    vSelect,
    ShipbluVSelect,
    ShipbluPrompt
  },
  methods: {
    convertTextToSelect () {
      this.shipment.full_name = ''
      this.shipment.phone = ''
      this.shipment.email = ''
      this.shipment.customer_governorate = ''
      this.shipment.customer_city = ''
      this.shipment.address.customerZone = ''
      this.shipment.handShakeType = ''
      this.shipment.secondary_phone = ''
      this.shipment.address.line_1 = ''
      this.shipment.address.line_2 = ''
      this.selectionInProgress = ''
    },
    handlePhoneNumberSearch (search) {
      this.searchPhoneVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadPhoneNumberSearch, 1000, 5)
    },
    loadPhoneNumberSearch () {
      if (this.searchInProgress || this.searchedPhoneValue === this.searchPhoneVal) {
        return
      }
      if (this.searchPhoneVal) {
        this.loadPhoneNumber()
      }
    }, 
    loadPhoneNumber () {
      this.searchInProgress = true
      sendRequest(false, false, this, `api/v1/delivery-orders/?customer_phone=${this.searchPhoneVal}`, 'get', null, true,
        (response) => { 
          this.phones = response.data.results
          this.phones.forEach(item => {
            item.label = `${item.customer.phone} ${item.customer.full_name}`
          })

          this.searchedPhoneValue = this.searchPhoneVal
          if (response.data.count === 0) {
            this.shipment.phone = this.searchedPhoneValue
          }
          this.selectionInProgress = this.phones.length === 0
        },
      )
      this.searchInProgress = false
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
      })
      this.$emit('loadServicesAvailability', governorateID)
      if (this.cities && this.shipment.customer_city) {
        this.loadZones(this.shipment.customer_city.id)
      }
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    },
    changeSize (shortCode) {
      switch (shortCode) {
      case 'S':
        return 'width: 20px;height: 25px'
      case 'M':
        return 'width: 25px; height: 30px;'
      case 'L':
        return 'width: 30px; height: 35px;'
      case 'XL':
        return 'width: 35px; height: 40px;'
      }
    },
    checkedItem (element, array) {
      array.forEach(item => {
        if (item.name === element.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    removePackage (index) {
      this.packages.splice(index, 1)
    },
    addPackage (item) {
      this.packages.push({
        package_size: item,
        description: '',
        fragile: false
      })
    },
    addMorePackage () {
      this.$validator.validate('package size').then(result => {
        if (result) {
          this.packages.push({
            package_size: '',
            description: '',
            fragile: false
          })
        }
      })
    },
    addShipment () {
      this.clicks++
      this.$validator.validateAll().then(result => {
        this.packages.length = this.orderType === 'Fulfillment' ? 1 : this.packages.length
        if (result && this.packages.length > 0) {
          this.shipment.phone = this.selectionInProgress ? this.searchPhoneVal : this.shipment.phone
          this.$emit('addShipment')
          this.$emit('selectionInProgress', this.selectionInProgress)
          this.$emit('orderType', this.orderType)
        }
      })
    },
    loadFinancialsInsurance () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchant.id}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.financialsInsurance = response.data
        }
      )
    },
    closeModal () {
      this.$emit('shipmentModal', false)
      this.clicks = 0
      this.zones = []
      this.phones = []
      this.$emit('estimatePrice', 0)
      this.showAdditional = false
    }
  }
}
</script>

<style lang='scss'>
.what3words-autosuggest-error-wrapper {
  display: none !important;
}
.text-darkgray {
  color: #475C6F;
}
.supply-table-cell-content {
  align-items: center;
  padding: 8px;
  background: #EBF5FF;
  border-radius: 4px;
}
.text-lightgray {
  color: #6C84A3;
}
.small-card {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.32);
  border-radius: 4px;
  text-align: center;
}
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
.estimated-price {
  background: #F5FAFF;
  border-radius: 4px;
  padding: 24px;
}
div.pickup-point .vs__dropdown-toggle {
  .vs__search {
    color: #6C84A3;
  }
  border: 0.6px solid #9DADC2 !important;
  border-radius: 4px;
  background: #F1F3F6;
}
div.pickup-point-filled .vs__dropdown-toggle {
  background: #FFFFFF;
  border: 0.6px solid #1C5BFE;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
  border-radius: 4px;
  font-size: 13px !important;
  font-weight: 500;
  .vs__selected-options span, .vs__actions button span, .vs__actions span {
    color: #1C5BFE;
  }
}
</style>