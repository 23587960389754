<template>
  <div>
    <div class="sm:flex mb-3 items-center gap-4 justify-between">
      <div class="flex items-center w-1/2 gap-2">
        <div class="border border-solid border-grey rounded-full w-4 h-4 flex font-bold justify-center">
          <feather-icon icon="PercentIcon" svgClasses="w-3 h-3" class="text-icon" />
        </div>
        <p class="font-medium text-sm text-grey-dark">{{$t('Promo Code')}}</p>
      </div>  
      <div class="sm:w-1/2 mt-2 w-full">
        <input class="input-order w-full" type="text" v-validate="'max:30'" name="promo code" :placeholder="$t('If you have a promo code')"  v-model="shipment.promoCode">
        <p class="text-danger text-sm" v-show="errors.has('promo code')">{{ errors.first('promo code') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['shipment']
}
</script>
