<template>
  <div>
    <shipblu-card class="md:p-6 p-5 mb-3">
      <div class="md:flex gap-4">
        <div class="md:w-1/2">
          <p class="mb-4 text-lg font-medium text-black">{{type === 'Add Delivery Order' ? $t('How will we get your package?') : $t('How will we return your package?')}}</p>
          <div class="flex flex-wrap gap-x-2 gap-y-6 service-level">
            <div v-for="(dropOff, index) in dropOffChoices" :key="index">
              <label :class="merchant.is_self_signup && merchant.self_signup_verified === null ? 'text-grey' : ''" @click="merchant.is_self_signup && merchant.self_signup_verified === null ? '' : checkedItem(dropOff, dropOffChoices)" class="cursor-pointer checked-border">
                <input :disabled="dropOff.id === 2 && merchant.is_self_signup && merchant.self_signup_verified === null" v-model="shipment.is_counter_dropoff" :class="dropOff.checked ? 'checked' : ''" :value="dropOff.id" type="radio" />
                <span class="py-2 pl-7 rounded font-medium leading-tight" :class="dropOff.name === 'Counter DropOff' ? 'pr-6' : 'pr-3'"> {{type === 'Add Delivery Order'? $t(dropOff.name) : $t(dropOff.name_2) }}</span>
                <vx-tooltip v-if="dropOff.name === 'Counter DropOff'" class="info-icon" position="top" color="dark" :text="`${ type === 'Add Delivery Order' ? $t('Choose this option if you will drop the Package to the nearest ShipBlu Branch') : 'Choose this option if you will get the Return Package from the ShipBlu warehouse' }`">
                  <span class="material-icons-outlined text-sm">info</span>
                </vx-tooltip>
              </label>
            </div>
          </div>
        </div>
        <div class="md:w-1/2 md:mt-0 mt-6" v-if="(shipment.is_counter_dropoff && (shipment.is_counter_dropoff === 2 || shipment.is_counter_dropoff.id === 2)) && type === 'Add Delivery Order'">
          <p class="text-lg font-medium text-black mb-2">{{type === 'Add Delivery Order' ? $t('Select your pickup Location:') : $t('Select your dropoff point:')}}</p>
          <v-select class="select-border w-full" v-model="shipment.pickupPoint" v-validate="'required'"
            :placeholder="$t('Pickup Point')" name="pickup point" label="fullAddress" :options="pickupPoints"/>
          <span class="text-danger text-sm" v-show="errors.has('pickup point')">{{ errors.first('pickup point') }}</span>
        </div>
      </div>
      <vs-alert class="mt-4" style="height: fit-content;" v-if="merchant.is_self_signup && merchant.self_signup_verified === null" icon-pack="material-icons" icon="error" color="warning">
        <span>{{$t('Your account is currently under review, you are welcome to drop off your packages at ')}} </span>
        <a class="font-bold underline" target="_blank" href="https://support.shipblu.com/ar/support/solutions/articles/154000170935-%D8%B9%D9%86%D9%88%D8%A7%D9%8A%D9%86-%D9%81%D8%B1%D9%88%D8%B9-%D8%B4%D9%8A%D8%A8-%D8%A8%D9%84%D9%88">{{$t('one of our locations')}}</a>
        <span>{{$t(' until your profile is verified.')}} </span>
      </vs-alert>
    </shipblu-card>
  </div>
</template>
<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import vSelect from 'vue-select'

export default {
  props: ['shipment', 'pickupPoints', 'type', 'merchant'],
  data () {
    return {
      dropOffChoices: [
        {
          id: 2,
          name: 'Pickup from your location',
          name_2: 'Return to your location',
          checked: !(this.merchant.is_self_signup && this.merchant.self_signup_verified === null)
        },
        {
          id: 1,
          name: 'Counter DropOff',
          name_2: 'Counter DropOff',
          checked: this.merchant.is_self_signup && this.merchant.self_signup_verified === null
        }
      ]
    }
  },
  watch: {
    'shipment.is_counter_dropoff' (val) {
      if (typeof (val) === 'object') {
        this.shipment.is_counter_dropoff = this.shipment.is_counter_dropoff.id
      }
    }
  },
  methods:{
    checkedItem (element, array) {
      array.forEach(item => {
        if (item.name === element.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    }
  },
  components: {
    ShipbluCard,
    vSelect
  }
}
</script>